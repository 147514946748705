import React from "react"
import { Link } from "gatsby"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <div className={"page-not-found"}>
    <Seo title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    <br />
    <Link to={"/"} className={"button primary"}>
      Go to home
    </Link>
  </div>
)

export default NotFoundPage
